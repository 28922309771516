// **  Initial State
const initialState = {
    navtex: [],
    total: 0
}

const navtexReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_NAVTEX':
            return {...state, total: action.total, navtex: action.payload}
        default:
            return state
    }
}

export default navtexReducer
