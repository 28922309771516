// **  Initial State
const initialState = {
    users: [],
    graphUsersData: {},
    total: 0,
    oneUserStatus: null,
    oneUserError: false,

}
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_USERS':
            return { ...state, total: action.total, users: action.payload }
        case 'GET_GRAPH_USERS_DATA':
            return { ...state, graphUsersData: action.payload }
        case 'GET_ONE_USER_STATUS':
            return { ...state, graphUsersData: action.payload }
        case 'GET_ONE_USER_ERROR':
            return { ...state, oneUserError: action.payload }
        default:
            return state
    }
}

export default userReducer
