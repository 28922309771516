// **  Initial State
const initialState = {
    priceoffs: [],
    total: 0,
    createPriceoffStatus: null,
    deletePriceoffStatus: null
}

const permissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_PRICEOFF':
            return {
                ...state,
                priceoffs: action.payload,
                total: action.total
            }
        case 'CREATE_PRICEOFF':
            return {
                ...state,
                createPriceoffStatus: action.status
            }
        case 'REFRESH_CREATE_PRICEOFF':
            return {
                ...state,
                createPriceoffStatus: action.status
            }

        case 'DELETE_PRICEOFF':
            return {
                ...state,
                deletePriceoffStatus: action.status
            }
        case 'REFRESH_DELETE_PRICEOFF':
            return {
                ...state,
                deletePriceoffStatus: action.status
            }
        default:
            return state
    }
}

export default permissionReducer
