// **  Initial State
const initialState = {
    fishes: [],
    tempFishes: [],
    total: 0,
    createFishStatus: null,
    createFishError: false,
    updateFishStatus: null,
    updateFishError: false,
    deleteFishStatus: false,
    reconcileStatus: false,
    bulkErrors: null,
    clearFishError: false,
}

const fishReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_FISHES':
            return {...state, total: action.total, fishes: action.payload}
        case 'TEMP_FISHES':
            return {...state, tempFishes: action.payload}
        case 'CREATE_FISH_STATUS':
            return {...state, createFishStatus: action.payload}
        case 'CREATE_FISH_ERROR':
            return {...state, createFishError: action.payload}
        case 'UPDATE_FISH_STATUS':
            return {...state, updateFishStatus: action.payload}
        case 'UPDATE_FISH_ERROR':
            return {...state, updateFishError: action.payload}
        case 'DELETE_FISH_STATUS':
            return {...state, deleteFishStatus: action.payload}
        case 'RECONCILE_STATUS':
            return {...state, reconcileStatus: action.payload}
        case 'CLEAR_FISH_STATUS':
            return {
                ...state,
                deleteFishStatus: false,
                createFishStatus: false,
                updateFishStatus: false,
                createFishError: false
            }
        case 'SAVE_BULK_ERROR':
            return {...state, bulkErrors: action.payload}
        case 'CLEAR_FISH_ERROR':
            return {...state, clearFishError: action.payload}
        default:
            return state
    }
}

export default fishReducer
