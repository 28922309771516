// **  Initial State
const initialState = {
    regions: [],
    total: 0,
    createRegionStatus: null,
    createRegionError: false,
    updateRegionStatus: null,
    updateRegionError: false,
    deleteRegionStatus: false
}

const regionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_REGIONS':
            return {...state, total: action.total, regions: action.payload}
        case 'CREATE_REGION_STATUS':
            return {...state, createRegionStatus: action.payload}
        case 'CREATE_REGION_ERROR':
            return {...state, createRegionError: action.payload}
        case 'UPDATE_REGION_STATUS':
            return {...state, updateRegionStatus: action.payload}
        case 'UPDATE_REGION_ERROR':
            return {...state, updateRegionError: action.payload}
        case 'DELETE_REGION_STATUS':
            return {...state, deleteRegionStatus: action.payload}
        case 'CLEAR_REGION_STATUS':
            return {
                ...state,
                deleteRegionStatus: false,
                createRegionStatus: false,
                updateRegionStatus: false,
                createRegionError: false,
                updateRegionError: false
            }
        default:
            return state
    }
}

export default regionReducer
