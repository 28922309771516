// **  Initial State
const initialState = {
    roles: [],
    total: 0,
    createRoleStatus: null,
    editRoleStatus: null,
    deleteRoleStatus: null
}

const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_ROLES':
            return {
                ...state,
                roles: action.payload,
                total: action.total
            }
        case 'CREATE_ROLE':
            return {
                ...state,
                createRoleStatus: action.status
            }
        case 'REFRESH_CREATE_ROLE':
            return {
                ...state,
                createRoleStatus: action.status
            }
        case 'EDIT_ROLE':
            return {
                ...state,
                editRoleStatus: action.status
            }
        case 'REFRESH_EDIT_ROLE':
            return {
                ...state,
                editRoleStatus: action.status
            }
        case 'DELETE_ROLE':
            return {
                ...state,
                deleteRoleStatus: action.status
            }
        case 'REFRESH_DELETE_ROLE':
            return {
                ...state,
                deleteRoleStatus: action.status
            }
        default:
            return state
    }
}

export default roleReducer
