// **  Initial State
const initialState = {
    sales: [],
    graphSalesData: {},
    total: 0,
    updateSalesStatus: null,
    updateSalesError: false
}

const salesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_SALES':
            return { ...state, total: action.total, sales: action.payload }
        case 'GET_GRAPH_SALES_DATA':
            return { ...state, graphSalesData: action.payload }
        case 'UPDATE_SALES_STATUS':
            return { ...state, updateSalesStatus: action.payload }
        case 'UPDATE_SALES_ERROR':
            return { ...state, updateSalesError: action.payload }
        case 'CLEAR_SALES_STATUS':
            return {
                ...state,
                updateSalesStatus: false,
                updateSalesError: false
            }
        default:
            return state
    }
}

export default salesReducer
