// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import user from './users'
import fish from './fish'
import navtex from './navtex'
import forbiddenZone from "./forbiddenZone"
import permission from "./permission"
import roles from "./roles"
import regions from "./region"
import sales from "./sales"
import priceoff from "./priceoff"
import yesFish from './yesFish'
import contestants from "./contestants"
import teams from "./teams"
import competition from "./competition"

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  user,
  fish,
  navtex,
  forbiddenZone,
  roles,
  permission,
  regions,
  sales,
  priceoff,
  yesFish,
  contestants,
  teams,
  competition,
})

export default rootReducer
