// **  Initial State
const initialState = {
    contestants: [],
    tallies: [],
    singleContestant: [],
    total: 0,
    createContestantStatus: null,
    createContestantError: false,
    editContestantStatus: null,
    updateContestantError: false,
    deleteContestantStatus: false
}
const contestantReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_CONTESTANTS':
            return { ...state, total: action.total, contestants: action.payload }
        case 'ALL_TALLY':
            return {
                ...state,
                // total: action.total,
                tallies: action.payload
            }
        case 'ONE_CONTESTANT':
            return {
                ...state,
                singleContestant: action.payload
            }
        case 'CREATE_CONTESTANT':
            return {
                ...state,
                createContestantStatus: action.status
            }
        case 'REFRESH_CREATE_CONTESTANT':
            return {
                ...state,
                createContestantStatus: action.status
            }
        case 'EDIT_CONTESTANT':
            return {
                ...state,
                editContestantStatus: action.status
            }
        case 'REFRESH_EDIT_CONTESTANT':
            return {
                ...state,
                editContestantStatus: action.status
            }
        case 'DELETE_CONTESTANT':
            return {
                ...state,
                deleteContestantStatus: action.status
            }
        case 'REFRESH_DELETE_CONTESTANT':
            return {
                ...state,
                deleteContestantStatus: action.status
            }
        case 'CLEAR_CONTESTANT_STATUS':
            return {
                ...state,
                deleteContestantStatus: false,
                createContestantStatus: false,
                editContestantStatus: false,
                createContestantError: false,
                updateContestantError: false
            }
        default:
            return state
    }
}

export default contestantReducer
