// **  Initial State
const initialState = {
    permissions: [],
    total: 0,
    createPermissionStatus: null,
    editPermissionStatus: null,
    deletePermissionStatus: null
}

const permissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_PERMISSIONS':
            return {
                ...state,
                permissions: action.payload,
                total: action.total
            }
        case 'CREATE_PERMISSION':
            return {
                ...state,
                createPermissionStatus: action.status
            }
        case 'REFRESH_CREATE_PERMISSION':
            return {
                ...state,
                createPermissionStatus: action.status
            }
        case 'EDIT_PERMISSION':
            return {
                ...state,
                editPermissionStatus: action.status
            }
        case 'REFRESH_EDIT_PERMISSION':
            return {
                ...state,
                editPermissionStatus: action.status
            }
        case 'DELETE_PERMISSION':
            return {
                ...state,
                deletePermissionStatus: action.status
            }
        case 'REFRESH_DELETE_PERMISSION':
            return {
                ...state,
                deletePermissionStatus: action.status
            }
        default:
            return state
    }
}

export default permissionReducer
