// **  Initial State
const initialState = {
    records: [],
    total: 0,
    createYesFishStatus: null,
    createYesFishError: false,
    updateYesFishStatus: null,
    updateYesFishError: false,
    deleteYesFishStatus: false,
}

const yesFishReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_RECORDS':
            return { ...state, total: action.total, records: action.payload }
        case 'CREATE_YESFISH_STATUS':
            return { ...state, createYesFishStatus: action.payload }
        case 'CREATE_YESFISH_ERROR':
            return { ...state, createYesFishError: action.payload }
        case 'UPDATE_YESFISH_STATUS':
            return { ...state, updateYesFishStatus: action.payload }
        case 'UPDATE_YESFISH_ERROR':
            return { ...state, updateYesFishError: action.payload }
        case 'DELETE_YESFISH_STATUS':
            return { ...state, deleteYesFishStatus: action.payload }
        case 'CLEAR_YESFISH_STATUS':
            return {
                ...state,
                deleteYesFishStatus: false,
                createYesFishStatus: false,
                updateYesFishStatus: false,
                createYesFishError: false,
                updateYesFishError: false,
            }
        default:
            return state
    }
}

export default yesFishReducer
