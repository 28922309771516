// **  Initial State
const initialState = {
    forbiddenZones: [],
    tempForbiddenZones: [],
    total: 0,
    createForbiddenZoneStatus: null,
    createForbiddenZoneError: false,
    updateForbiddenZoneStatus: null,
    updateForbiddenZoneError: false,
    deleteForbiddenZoneStatus: false
}

const forbiddenZoneReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_FORBIDDENZONES':
            return {...state, total: action.total, forbiddenZones: action.payload}
        case 'TEMP_FORBIDDENZONES':
            return {...state, tempForbiddenZones: action.payload}
        case 'CREATE_FORBIDDENZONE_STATUS':
            return {...state, createForbiddenZoneStatus: action.payload}
        case 'CREATE_FORBIDDENZONE_ERROR':
            return {...state, createForbiddenZoneError: action.payload}
        case 'UPDATE_FORBIDDENZONE_STATUS':
            return {...state, updateForbiddenZoneStatus: action.payload}
        case 'UPDATE_FORBIDDENZONE_ERROR':
            return {...state, updateForbiddenZoneError: action.payload}
        case 'DELETE_FORBIDDENZONE_STATUS':
            return {...state, deleteForbiddenZoneStatus: action.payload}
        case 'CLEAR_FORBIDDENZONE_STATUS':
            return {
                ...state,
                deleteForbiddenZoneStatus: false,
                createForbiddenZoneStatus: false,
                updateForbiddenZoneStatus: false,
                createForbiddenZoneError: false
            }
        default:
            return state
    }
}

export default forbiddenZoneReducer
