// **  Initial State
const initialState = {
    competitions: [],
    total: 0,
    createCompetitionStatus: null,
    editCompetitionStatus: null,
    deleteCompetitionStatus: null
}

const competitionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_COMPETITIONS':
            return {
                ...state,
                competitions: action.payload,
                total: action.total
            }
        case 'CREATE_COMPETITION':
            return {
                ...state,
                createCompetitionStatus: action.status
            }
        case 'REFRESH_CREATE_COMPETITION':
            return {
                ...state,
                createCompetitionStatus: action.status
            }
        case 'EDIT_COMPETITION':
            return {
                ...state,
                editCompetitionStatus: action.status
            }
        case 'REFRESH_EDIT_COMPETITION':
            return {
                ...state,
                editCompetitionStatus: action.status
            }
        case 'DELETE_COMPETITION':
            return {
                ...state,
                deleteCompetitionStatus: action.status
            }
        case 'REFRESH_DELETE_COMPETITION':
            return {
                ...state,
                deleteCompetitionStatus: action.status
            }
        default:
            return state
    }
}

export default competitionReducer
