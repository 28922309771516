// **  Initial State
const initialState = {
    teams: [],
    teamsofclub: [],
    total: 0,
    editTeamStatus: null,
    updateTeamError: false,
}

const TeamReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_TEAMS':
            return { ...state, total: action.total, teams: action.payload }
        case 'TEAMSOFCLUB':
            return { ...state, total: action.total, teamsofclub: action.payload }
        default:
            return state
        case 'EDIT_TEAM':
            return {
                ...state,
                editTeamStatus: action.status
            }
        case 'REFRESH_EDIT_TEAM':
            return {
                ...state,
                editTeamStatus: action.status
            }
        case 'CLEAR_TEAM_STATUS':
            return {
                ...state,
                editTeamStatus: false,
                updateTeamError: false
            }
    }
}

export default TeamReducer
